import React from "react";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components/macro";

import { Button, Row, Col, Input, Hidden, Icon } from "../../../../components";
import Summary from "../../../../components/Booking/Summary";
import User from "../../../../components/Booking/User";
import Questions from "../../../../components/Booking/Questions";
import Title from "../../../Event/Title";
import Events from "./Events";
import { ActionBar, SubHeading, SummaryWrapper } from "../../../../components/Booking/Booking";
import Product from "../../../../components/Product";

const USER_FORM_ID = "user-form";

const BorderedCol = styled(Col)`
  border-left: 1px solid ${props => props.theme.gray200};
`;

const TermsLink = styled.p`
  margin-bottom: 0.125rem;
  @media (${props => props.theme.tabletScreen}) {
    margin-bottom: 0.5rem;
  }
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.25rem 0 0.5rem;

  button {
    flex: 1;
  }

  ${TermsLink} {
    font-size: ${props => props.theme.fontSizes.tiny};
  }

  @media (${props => props.theme.tabletScreen}) {
    margin-top: 3rem;
    ${TermsLink} {
      display: block;
    }
  }

  // Android Chrome soft keyboard
  @media (max-height: 400px) {
    button {
      margin: 1rem 0 0.25rem;
    }
  }
`;

const FormWrapper = styled.div`
  margin-bottom: 200px;

  @media (${props => props.theme.tabletScreen}) {
    margin-bottom: 0.5rem;
  }
`;

const ProductContract = ({
  product,
  userData,
  provider,
  event,
  onSubmit,
  participantName,
  updateParticipantName,
  updateUser,
  validations,
  questions,
  updateQuestions,
  onCancel,
}) => (
  <Row>
    <Col count={7}>
      <FormWrapper>
        <Title course={event.course} event={event} image={provider.logo} />
        <Product product={product} />
        <Events product={product} />
        <form onSubmit={onSubmit} id={USER_FORM_ID}>
          <SubHeading>
            <FormattedMessage id="booking.attributes.participants" />
          </SubHeading>
          <Input
            name="participantName"
            value={participantName}
            onChange={({ target: { value } }) => updateParticipantName(value)}
            maxLength={25}
            required
          />
          {questions && questions.length > 0 && (
            <Questions questions={questions} onUpdate={updateQuestions} />
          )}
          <User user={userData} onUpdate={updateUser} forceSignup validations={validations} />
        </form>
      </FormWrapper>
    </Col>
    <BorderedCol count={5}>
      <SummaryWrapper>
        <Summary
          participants={[participantName]}
          costs={product.price / 100}
          serviceFee={0}
          priceOption={{
            recurring: true,
            price: product.price,
            intervalType: product.intervalType,
            intervalCount: product.intervalCount,
            periodCount: product.periodCount,
            typeOf: product.typeOf,
            participantMultiplier: 1,
          }}
          course={event.course}
          event={event}
          provider={provider}
        />
        <CTA>
          <TermsLink>
            {provider.hasTerms ? (
              <FormattedHTMLMessage
                id="components.Booking.termsProvider"
                values={{ name: provider.name, slug: provider.slug }}
              />
            ) : (
              <FormattedHTMLMessage id="components.Booking.terms" />
            )}
          </TermsLink>
          <TermsLink>
            {provider.hasPrivacyPolicy ? (
              <FormattedHTMLMessage
                id="components.Booking.privacyPolicyProvider"
                values={{ name: provider.name, slug: provider.slug }}
              />
            ) : (
              <FormattedHTMLMessage id="components.Booking.privacyPolicy" />
            )}
          </TermsLink>

          <ActionBar>
            <Hidden size="md, lg">
              <Button
                type="button"
                color="default"
                glow={false}
                onClick={e => {
                  e.preventDefault();
                  onCancel();
                }}
                style={{ flex: 0, marginRight: "0.5rem" }}
              >
                <Icon name="chevron-left" />
              </Button>
            </Hidden>
            <Button
              type="submit"
              color="primary"
              glow
              disabled={false}
              // onClick={onSubmit}
              form={USER_FORM_ID}
            >
              <FormattedMessage id="actions.subscribe" />
            </Button>
          </ActionBar>
        </CTA>
      </SummaryWrapper>
    </BorderedCol>
  </Row>
);

ProductContract.propTypes = {
  product: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  questions: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  participantName: PropTypes.string,
  validations: PropTypes.object,

  // Dispatch
  updateParticipantName: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateQuestions: PropTypes.func.isRequired,
};

ProductContract.defaultProps = {
  participantName: "",
  validations: {},
};

export default ProductContract;
