import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from "react-intl";

import Summary from "./Summary";
import Voucher from "./Voucher";
import Participants, { MAX_PARTICIPANT_COUNT, MIN_PARTICIPANT_COUNT } from "./Participants";
import Questions from "./Questions";
import User from "./User";
import Password from "./Password";
import { Loading, Row, Col, Heading5, Textarea, Button, Link, Hidden, Icon } from "../";
import Title from "../../pages/Event/Title";
import { EVENT_TYPES } from "../../utils/courses";
import { USER_TEMPLATE } from "../../store/reducers/user";
import { useRollbar } from "@rollbar/react";

const USER_FORM_ID = "userForm";
const MAX_CARD_PRICE_CENTS = 50000;
const MAX_MULTI_PARTICIPANT_PRICE_CENTS = 50000;

const BOOKING_TEMPLATE = {
  note: "",
  status: "created",
  createdAt: Date.now(),
  participants: [],
};

export const defaultParticipants = (length, names) =>
  Array.from({ length }).map((c, i) => ({
    name: (names || [""])[i],
    key: `participant-${(Math.random() + 1).toString(36).substring(7)}`,
  }));

export const calcBaseCosts = (priceOption, participants) =>
  Math.ceil((participants || []).length / priceOption.participantMultiplier) *
  (priceOption.price / 100);

const BorderedCol = styled(Col)`
  border-left: 1px solid ${props => props.theme.gray200};
`;

const Note = styled.section`
  margin-top: 1.5rem;
`;

export const SubHeading = styled(Heading5)`
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  margin-left: -0.5rem;
`;

export const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: fixed;
  bottom: 0;
  left: 0;
  min-width: 100%;
  padding: 1rem 0.75rem 0;
  background-color: ${props => props.theme.white};
  box-shadow: 0 -1rem 3.5rem -1.25rem rgba(0, 0, 0, 0.25);
  border-radius: ${props => props.theme.borderRadius};
  z-index: 1;

  @media (${props => props.theme.tabletScreen}) {
    position: static;
    box-shadow: none;
    min-height: 100%;
    padding: 1rem;
    background-color: ${props => props.theme.light};
  }
`;

const TermsLink = styled.p`
  margin-bottom: 0.125rem;
  @media (${props => props.theme.tabletScreen}) {
    margin-bottom: 0.5rem;
  }
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.25rem 0 0.5rem;

  button {
    flex: 1;
  }

  ${TermsLink} {
    font-size: ${props => props.theme.fontSizes.tiny};
  }

  @media (${props => props.theme.tabletScreen}) {
    margin-top: 3rem;
    ${TermsLink} {
      display: block;
    }
  }

  // Android Chrome soft keyboard
  @media (max-height: 400px) {
    button {
      margin: 1rem 0 0.25rem;
    }
  }
`;

export const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(249, 250, 250, 0.9);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  padding: 1rem;
  z-index: 4;

  & > div {
    background: transparent;
    box-shadow: none;
  }
`;

const AuthorizeContainer = styled.div`
  text-align: center;
`;

export const CancelLink = styled(Link)`
  display: block;
  font-size: ${props => props.theme.fontSizes.small};
  text-decoration: underline;
  margin-top: 0.5rem;
  color: ${props => props.theme.gray600};
  font-size: ${props => props.theme.fontSizes.small};

  @media (${props => props.theme.tabletScreen}) {
    color: ${props => props.theme.black};
    margin-top: 1rem;
  }
`;

export const ActionBar = styled.div`
  display: flex;
  margin-top: 0.4rem;
  justify-content: flex-end;
  align-items: center;
`;

// TODO:
// Due to transition to url based booking state, rewrite booking to use hooks and only persist in redux
// state on blur / unload. Also only load persisted booking once and maybe ask user if continue or not.
// Also split booking and checkout with their concerns:
//
// booking - handle kikudoo booking form
// checkout - after successful booking, take the returned payment intent id and process payment
// success - display redesigned success screen with CTAs and incentives
const Booking = ({
  course,
  event,
  priceOption,
  userData,
  createBooking,
  login,
  intl,
  origin,
  isLoading,
  onCancel,
  onSuccess,
}) => {
  const rollbar = useRollbar();
  const [serviceFee, setServiceFee] = useState(0);
  const [voucher, setVoucher] = useState(null);
  const [participants, setParticipants] = useState(
    defaultParticipants(priceOption.participantMultiplier, (userData || {}).participantNames),
  );
  const [costs, setCosts] = useState(0);
  const [password, setPassword] = useState(null);
  const [user, setUser] = useState(
    userData || {
      ...USER_TEMPLATE,
      country: (event && event.address && event.address.country) || "DE",
    },
  );
  const [questions, setQuestions] = useState(
    Object.keys(event.bookingQuestions || []).map(key => ({
      ...event.bookingQuestions[key],
      value: "",
      key,
    })),
  );
  const [note, setNote] = useState("");

  const updateCosts = useCallback(() => {
    const price = priceOption.price / 100;
    let costs = calcBaseCosts(priceOption, participants);

    if (voucher && costs >= voucher.minBookingAmount) {
      // If an voucher is applied, reduce by either cash or percentage amount
      costs -= voucher.unit === "cash" ? voucher.value : price * (voucher.value / 100);
    }

    setCosts(Math.max(costs, 0));
  }, [participants, priceOption, voucher]);

  // Initial costs calculation
  useEffect(() => {
    updateCosts();

    return () => {
      // clean up
    };
  }, [updateCosts]);

  // Update service fee if costs or course changed
  useEffect(() => {
    const serviceFee = course.serviceFeeIncluded
      ? 0
      : Math.min(costs * course.serviceFeeRate, course.maxServiceFee || Number.POSITIVE_INFINITY);

    setServiceFee(serviceFee);
    return () => {
      setServiceFee(0);
    };
  }, [costs, course]);

  // In case the booking is protected by a password validate password before show booking dialog
  const onAuthorize = password => {
    setPassword(password);
  };

  // When user's details are filled out, continue with
  // booking creation. A successfully created booking should
  // contain an id and a clientSecret (Stripe PaymentIntent).
  const onSubmit = async e => {
    if (e) {
      e.preventDefault();
    }

    try {
      const booking = await createBooking(
        {
          ...BOOKING_TEMPLATE,
          course,
          event,
          provider: { ...course.provider },
          questions,
          origin,
          priceOption,
          participants,
          password,
          voucher,
          note,
        },
        {
          ...user,
          passwordConfirmation: user.password,
        },
      );

      // Booking creation succeded, check fo the payment secret
      onSuccess(booking, user);
    } catch (error) {
      rollbar.error("Error creating booking", error);
      const { response } = error;

      if (!error || !response) {
        return window.alert(intl.formatMessage({ id: "components.Booking.error.unknown" }));
      }

      if (response.status === 401) {
        window.alert(intl.formatMessage({ id: "components.Booking.error.password" }));
      } else if (response.status === 422) {
        window.alert(
          intl.formatMessage({ id: `components.Booking.error.${Object.keys(response.data)[0]}` }),
        );
      } else if (response.status === 429) {
        window.alert(intl.formatMessage({ id: "components.Booking.error.tooManyRequests" }));
      } else if (response.status === 400) {
        window.alert(intl.formatMessage({ id: "components.Booking.error.badRequest" }));
      } else {
        window.alert(intl.formatMessage({ id: "components.Booking.error.unknown" }));
      }
    }

    // If we signed up a new user, try to log in
    if (user.password && user.password.length) {
      try {
        login({ email: user.email, password: user.password });
      } catch (e) {
        // ignore login errors for now
        rollbar.error("Error login user", e, { user });
      }
    }
  };

  const provider = course.provider;
  const needsAuthorization = event.isProtected && (!password || password === "");
  const maxCalcParticipantCountForPrice = Math.min(
    event.participantsLeft,
    priceOption.price >= MAX_MULTI_PARTICIPANT_PRICE_CENTS
      ? 1
      : Math.max(
          MIN_PARTICIPANT_COUNT,
          Math.min(MAX_PARTICIPANT_COUNT, Math.floor(MAX_CARD_PRICE_CENTS / priceOption.price)),
        ),
  );
  const maxParticipantCountForPrice =
    priceOption.maxParticipantCount > 0
      ? Math.min(priceOption.maxParticipantCount, maxCalcParticipantCountForPrice)
      : maxCalcParticipantCountForPrice;

  if (needsAuthorization) {
    return (
      <AuthorizeContainer>
        <Password event={event} onSuccess={onAuthorize} />
        <Link onClick={onCancel} muted>
          <small>
            <FormattedMessage id="actions.cancelBooking" />
          </small>
        </Link>
      </AuthorizeContainer>
    );
  }

  return (
    <Row>
      {isLoading && (
        <LoadingContainer>
          <Loading text={<FormattedMessage id="components.Booking.loading.booking" />} />
        </LoadingContainer>
      )}

      <Col count={7}>
        <Title course={course} event={event} priceOption={priceOption} />
        {priceOption.price > 0 && (
          <Voucher voucher={voucher} course={course} updateVoucher={setVoucher} />
        )}
        <form onSubmit={onSubmit} id={USER_FORM_ID}>
          <Participants
            participants={participants}
            onUpdate={setParticipants}
            participantsLeft={event.participantsLeft}
            multiplier={priceOption.participantMultiplier || 1}
            maxParticipants={maxParticipantCountForPrice}
          />
          {questions && questions.length > 0 && (
            <Questions questions={questions} onUpdate={setQuestions} />
          )}
          <Note>
            <SubHeading>
              <FormattedMessage id="components.Booking.noteToProvider" />
            </SubHeading>
            <Textarea
              name="note"
              onChange={({ target }) => setNote(target.value)}
              rows="3"
              value={note}
            />
          </Note>
          <User
            user={user}
            onUpdate={setUser}
            forceSignup={event.typeOf === EVENT_TYPES.onDemand}
          />
        </form>
        <CancelLink href="#" onClick={onCancel}>
          <FormattedMessage id="actions.cancelBooking" />
        </CancelLink>
      </Col>
      <BorderedCol count={5}>
        <SummaryWrapper>
          <Summary
            participants={participants}
            costs={costs}
            serviceFee={serviceFee}
            voucher={voucher}
            priceOption={priceOption}
            course={course}
            event={event}
            provider={provider}
          />
          <CTA>
            <TermsLink as="div">
              <TermsLink>
                {provider.hasTerms ? (
                  <FormattedHTMLMessage
                    id="components.Booking.termsProvider"
                    values={{ name: provider.name, slug: provider.slug }}
                  />
                ) : (
                  <FormattedHTMLMessage id="components.Booking.terms" />
                )}
              </TermsLink>
              <TermsLink>
                {provider.hasPrivacyPolicy ? (
                  <FormattedHTMLMessage
                    id="components.Booking.privacyPolicyProvider"
                    values={{ name: provider.name, slug: provider.slug }}
                  />
                ) : (
                  <FormattedHTMLMessage id="components.Booking.privacyPolicy" />
                )}
              </TermsLink>
            </TermsLink>

            <ActionBar>
              <Hidden size="md, lg">
                <Button
                  type="button"
                  color="default"
                  glow={false}
                  onClick={e => {
                    e.preventDefault();
                    onCancel();
                  }}
                  style={{ flex: 0, marginRight: "0.5rem" }}
                >
                  <Icon name="chevron-left" />
                </Button>
              </Hidden>
              <Button
                type="submit"
                color="primary"
                glow
                disabled={false}
                busy={isLoading}
                // onClick={this.onUserFormClick}
                form={USER_FORM_ID}
              >
                {priceOption.price > 0 ? (
                  <FormattedMessage id="actions.next" />
                ) : (
                  <FormattedMessage id="actions.book" />
                )}
              </Button>
            </ActionBar>
          </CTA>
        </SummaryWrapper>
      </BorderedCol>
    </Row>
  );
};

Booking.propTypes = {
  course: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  userData: PropTypes.object,
  createBooking: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  origin: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
};

Booking.defaultProps = {
  origin: "kikudoo",
};

export default injectIntl(Booking);
