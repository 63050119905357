import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl } from "react-intl";
import styled from "styled-components/macro";
import { useHistory } from "react-router-dom";
import { mapKeys, camelCase } from "lodash";
import routes, { routeWithParams } from "../../../../routes";
import { getSearchParams } from "../../../../utils/params";
import { ApiClient } from "../../../../utils/Api";
import { USER_PROPERTIES, USER_TEMPLATE } from "../../../../store/reducers/user";

import { PageLayout, Alert, Loading, Container, Icon, Link } from "../../../../components";

import Checkout from "./Checkout";
import ProductContract from "./ProductContract";
import { pick } from "lodash";
import { BookingOverlay } from "../../../Checkout";
import Password from "../../../../components/Booking/Password";
import { useRollbar } from "@rollbar/react";

// To create api client scope for cancable requests
const apiClient = new ApiClient();

const LoadingWrapper = styled.div`
  min-height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.white};
`;

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.55);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  & > div {
    box-shadow: ${props => props.theme.boxShadowLifted};
  }
`;

export const Wrapper = styled(Container)`
  background-color: ${props => props.theme.white};
  min-height: 100%;
  text-align: left;
  position: relative;
  padding: 0.75rem;
  box-shadow: ${props => props.theme.boxShadowLifted};

  @media (${props => props.theme.tabletScreen}) {
    border-radius: ${props => props.theme.borderRadius};
    padding: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

export const CancelButton = styled.button`
  border: 0;
  padding: 0.4rem 0.5rem;
  background-color: ${props => props.theme.white};
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 50%;
  color: ${props => props.theme.gray500};
  cursor: pointer;
  z-index: 1;
  &:hover {
    color: ${props => props.theme.black};
  }
`;

const Confirm = ({ match, createContract, userData, login, intl }) => {
  const rollbar = useRollbar();
  const [productId] = useState(match.params.id);
  const [product, setProduct] = useState(null);
  const [event, setEvent] = useState(null);
  const [password, setPassword] = useState(null);

  const [user, setUser] = useState(
    userData || {
      ...USER_TEMPLATE,
      country: "DE",
    },
  );
  const [participantName, setParticipantName] = useState(
    userData ? `${userData.firstName} ${userData.lastName}` : "",
  );
  const [contract, setContract] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [validations, setValidations] = useState({});

  const [questions, setQuestions] = useState([]);

  const history = useHistory();
  const params = getSearchParams() || {};

  useEffect(() => {
    if (!productId) {
      return;
    }

    const getProductContract = async id => {
      try {
        const data = await apiClient.product(id);
        setProduct(data);
      } catch (e) {
        if (e.isAxiosError) {
          const status = e.response.status;
          return setError(status || 500);
        }
        setError(500);
      }
    };
    getProductContract(productId);
  }, [productId]);

  useEffect(() => {
    if (!product) {
      return;
    }

    setEvent(product.events.find(e => e.id === parseInt(params.eventId)) || product.events[0]);
  }, [product, params]);

  useEffect(() => {
    if (!event) {
      return;
    }

    setQuestions(
      Object.keys(event.bookingQuestions || []).map(key => ({
        ...event.bookingQuestions[key],
        value: "",
        key,
      })),
    );
  }, [event]);

  const onSubmit = async e => {
    if (e) {
      e.preventDefault();
    }

    setIsLoading(true);

    try {
      const data = await createContract(
        { productId, participantName, questions, eventId: event.id || params.eventId, password },
        pick(user, USER_PROPERTIES),
      );

      // If we signed up a new user, try to log in before proceed
      if (user.password && user.password.length) {
        try {
          await login({ email: user.email, password: user.password });
        } catch (e) {
          setError(401);
        }
      }

      // TODO: probably no need for that anymore
      setContract(data);
      setIsLoading(false);

      return history.push(
        routeWithParams(routes.booking.checkout, {
          slug: data.booking.provider.slug,
          id: data.booking.id,
        }),
      );
    } catch (e) {
      setIsLoading(false);
      rollbar.error("Error creating contract", e, {
        productId,
        participantName,
        eventId: event.id || params.eventId,
      });

      if (e.isAxiosError) {
        const status = e.response.status;
        switch (status) {
          case 401: {
            return window.alert(intl.formatMessage({ id: "components.Booking.error.password" }));
          }
          case 422: {
            const validationMessages = mapKeys(e.response.data || {}, (v, k) => camelCase(k));

            if (!(validationMessages || {}).productContract) {
              return window.alert(intl.formatMessage({ id: "components.Booking.error.unknown" }));
            }

            return setValidations((validationMessages || {}).productContract);
          }
          case 429: {
            return window.alert(
              intl.formatMessage({ id: "components.Booking.error.tooManyRequests" }),
            );
          }
          default: {
            return window.alert(intl.formatMessage({ id: "components.Booking.error.unknown" }));
          }
        }
      }
    }
  };

  const onCancel = () => {
    if (params.from) {
      return history.push(params.from);
    }

    if (product && (product.events || []).length > 0) {
      const course = product.events[0].course;
      return history.push(
        routeWithParams(routes.course, { slug: product.provider.slug, id: course.id }),
      );
    }

    history.push(routeWithParams(routes.provider, { slug: product.provider.slug }));
  };

  // In case the booking is protected by a password validate password before show booking dialog
  const onAuthorize = password => {
    setPassword(password);
  };

  if (error && error >= 500) {
    return (
      <PageLayout>
        <BookingOverlay centered blackout onClose={onCancel}>
          <Wrapper>
            <CancelButton onClick={onCancel}>
              <Icon name="add" direction={45} />
            </CancelButton>
            <Alert type="danger">
              <FormattedMessage id="components.Booking.error.unknown" />
              <br />
              <span>Error-Code:</span>
              <strong>{error}</strong>
            </Alert>
          </Wrapper>
        </BookingOverlay>
      </PageLayout>
    );
  }

  if (!product || !event) {
    return (
      <PageLayout>
        <BookingOverlay centered blackout onClose={onCancel}>
          <Wrapper>
            <CancelButton onClick={onCancel}>
              <Icon name="add" direction={45} />
            </CancelButton>
            <LoadingWrapper>
              <Loading />
            </LoadingWrapper>
          </Wrapper>
        </BookingOverlay>
      </PageLayout>
    );
  }

  if (event.isProtected && !password) {
    return (
      <PageLayout>
        <BookingOverlay centered blackout onClose={onCancel}>
          <Wrapper>
            <CancelButton onClick={onCancel}>
              <Icon name="add" direction={45} />
            </CancelButton>
            <Password event={event} onSuccess={onAuthorize} />
            <Link onClick={onCancel} muted>
              <small>
                <FormattedMessage id="actions.cancelBooking" />
              </small>
            </Link>
          </Wrapper>
        </BookingOverlay>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <BookingOverlay centered blackout onClose={onCancel}>
        <Wrapper>
          <CancelButton onClick={onCancel}>
            <Icon name="add" direction={45} />
          </CancelButton>
          {isLoading && (
            <LoadingContainer>
              <Loading text={<FormattedMessage id="components.Booking.loading.booking" />} />
            </LoadingContainer>
          )}

          {contract ? (
            <Checkout sessionId={contract.sessionId} onError={setError} onCancel={onCancel} />
          ) : (
            <ProductContract
              product={product}
              provider={product.provider}
              userData={user}
              updateUser={setUser}
              participantName={participantName}
              updateParticipantName={setParticipantName}
              event={event}
              onSubmit={onSubmit}
              validations={validations}
              questions={questions || []}
              updateQuestions={setQuestions}
              onCancel={onCancel}
            />
          )}
        </Wrapper>
      </BookingOverlay>
    </PageLayout>
  );
};

Confirm.propTypes = {
  match: PropTypes.object.isRequired,
  userData: PropTypes.object,
  token: PropTypes.string,
  // Dispatch
  createContract: PropTypes.func.isRequired,
};

Confirm.defaultProps = {
  userData: null,
  token: null,
};

export default injectIntl(Confirm);
