import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage } from "react-intl";

import { Label, Input } from "../";
import { SubHeading } from "./Booking";

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
`;

const BookingQuestion = styled(Label)`
  min-width: 100%;
  display: flex;
  flex-direction: ${props => (props.checkbox ? "row-reverse" : "column")};
  justify-content: ${props => (props.checkbox ? "flex-end" : "")};
  align-items: ${props => (props.checkbox ? "center" : "baseline")};
  margin-bottom: 1rem;

  & > input {
    width: ${props => (props.checkbox ? "auto" : "100%")};
    margin-bottom: 0;
    margin-right: ${props => (props.checkbox ? "1rem" : "0")};
  }
`;

const Questions = ({ questions, onUpdate }) => {
  const questionValue = questionKey => {
    const question = questions.find(({ key }) => key === questionKey);
    return question ? question.value : question.type === "checkbox" ? "true" : "";
  };

  const onChange = ({ target: { name, value, checked } }) => {
    onUpdate(
      questions.map(q =>
        q.key === name ? { ...q, value: q.type === "checkbox" ? checked : value } : q,
      ),
    );
  };

  return (
    <Wrapper>
      <SubHeading>
        <FormattedMessage id="booking.attributes.questions" />
      </SubHeading>
      <ol>
        {questions.map(({ key, label, type, mandatory }) => (
          <li key={key}>
            <BookingQuestion checkbox={type === "checkbox"}>
              <span>{`${label} ${mandatory ? "*" : ""}`}</span>
              <Input
                name={key}
                type={type}
                required={mandatory}
                onChange={onChange}
                value={questionValue(key)}
                defaultChecked={type === "checkbox" ? questionValue(key) === true : false}
              />
            </BookingQuestion>
          </li>
        ))}
      </ol>
    </Wrapper>
  );
};

Questions.propTypes = {
  questions: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default Questions;
