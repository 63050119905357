import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from "react-intl";
import { ApiClient } from "../../utils/Api";

import { Small, Button, Input, ActionInput, Icon, Hidden } from "../";
import { SubHeading } from "./Booking";

const Wrapper = styled.div`
  @media (${props => props.theme.tabletScreen}) {
    padding-right: 1rem;
  }
`;

const Section = styled.div`
  margin-bottom: 1.5rem;
`;

const Hint = styled(Small)`
  margin-bottom: 0.3rem;
`;

const DeleteButton = styled.button`
  border: 0;
  background-color: ${props => props.theme.white};
  color: ${props => props.theme.danger};
  padding: 0;
  vertical-align: middle;
  font-size: ${props => props.theme.fontSizes.small};
  margin-left: 0.5rem;
`;

const Voucher = ({ voucher, course, updateVoucher, intl }) => {
  const [voucherCode, setVoucherCode] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Check if voucher can be applied and store it in state
  const applyVoucher = async () => {
    if (isLoading) {
      return;
    }

    if (voucherCode === "" || voucher) {
      return;
    }

    setIsLoading(true);
    setError(false);

    try {
      const voucher = await new ApiClient().voucher(
        course.provider.id,
        course.id,
        voucherCode.trim(),
      );
      updateVoucher(voucher);
    } catch {
      setError(true);
      window.alert(intl.formatMessage({ id: "components.Booking.voucher.error" }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Wrapper>
      <Section>
        <SubHeading>
          <FormattedMessage id="booking.attributes.voucher" />
        </SubHeading>
        {voucher ? (
          <Hint>
            <FormattedHTMLMessage
              id="components.Booking.voucher.usage"
              values={{ code: voucher.code }}
            />
            <DeleteButton onClick={() => updateVoucher(null)}>
              <FormattedMessage id="actions.remove.normal" />
            </DeleteButton>
          </Hint>
        ) : (
          <article>
            <Hidden size={["sm"]}>
              <Hint>
                <FormattedMessage id="components.Booking.voucher.description" />
              </Hint>
            </Hidden>
            <form
              id="voucher"
              onSubmit={e => {
                e.preventDefault();
                applyVoucher(voucherCode);
              }}
            >
              <ActionInput>
                <FormattedMessage id="components.Booking.voucher.placeholder">
                  {placeholder => (
                    <Input
                      name="voucherCode"
                      value={voucherCode}
                      placeholder={placeholder}
                      onChange={({ target }) => setVoucherCode(target.value)}
                      onBlur={() => applyVoucher()}
                      hasError={error}
                    />
                  )}
                </FormattedMessage>
                <Button type="submit" color="info" disabled={voucherCode === ""} busy={isLoading}>
                  <Hidden size={["lg", "md"]}>
                    <Icon name="add" size="small" />
                  </Hidden>
                  <Hidden size={["sm"]}>
                    <FormattedMessage id="components.Booking.voucher.submit" />
                  </Hidden>
                </Button>
              </ActionInput>
            </form>
          </article>
        )}
      </Section>
    </Wrapper>
  );
};

Voucher.propTypes = {
  voucher: PropTypes.object,
  course: PropTypes.object.isRequired,
  updateVoucher: PropTypes.func.isRequired,
};

export default injectIntl(Voucher);
