import React, { useState, useEffect } from "react";
// https://stripe.dev/react-stripe-elements/
import { CardElement, IbanElement, useStripe, useElements } from "@stripe/react-stripe-js";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from "react-intl";
import { values, includes, capitalize } from "lodash";
import { ApiClient } from "../../utils/Api";

import { Input, Row, Col, Heading5, Icon, Loading, Button, Hidden } from "../";
import { SummaryWrapper, CancelLink, LoadingContainer, ActionBar } from "../Booking/Booking";
import Summary from "../Booking/Summary";
import DirectPay from "./DirectPay";
import stripeLogo from "../../assets/powered-by-stripe.svg";
import visa from "../../assets/visa.png";
import masterCard from "../../assets/master-card.png";
import sepa from "../../assets/lastschriftlogo.png";
import sofort from "../../assets/sofortLogo.png";
import giropay from "../../assets/giropay.png";
import { useRollbar } from "@rollbar/react";

export const PAYMENT_TYPES = {
  CARD: "card",
  SEPA: "sepa_debit",
  BUTTON: "payment_request_button",
  SAVED: "saved",
  GIRO_PAY: "giropay",
  SOFORT: "sofort",
};

export const validPaymentType = (desiredType = PAYMENT_TYPES.CARD, availableTypes = null) => {
  availableTypes = availableTypes || values(PAYMENT_TYPES);

  if (includes(availableTypes, desiredType)) {
    return desiredType;
  }

  if (includes(availableTypes, PAYMENT_TYPES.CARD)) {
    return PAYMENT_TYPES.CARD;
  }

  return availableTypes[0];
};

export const checkoutRedirectUrl = booking =>
  `${window.location.origin}/${booking.provider.slug}/checkout-redirect/${booking.id}`;

export const paymentTypeIcon = paymentType => {
  switch (paymentType) {
    case PAYMENT_TYPES.SEPA:
      return "money-check-pen";
    case PAYMENT_TYPES.CARD:
      return "credit-card";
    default:
      break;
  }

  return capitalize(paymentType);
};

const Wrapper = styled.div`
  margin-bottom: 2rem;

  @media (${props => props.theme.tabletScreen}) {
    padding-right: 1rem;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
`;

const Title = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`;

const stripeElementStyle = {
  base: {
    fontSize: "16px",
    padding: "1rem",
    margin: "2rem",
  },
};

const ElementWrapper = styled.div`
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  background-color: ${props => props.theme.light};
  background-clip: padding-box;
  border: 1px solid ${props => (props.hasError ? props.theme.red : props.theme.light)};
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: 1rem;
`;

const ElementWrapperSaved = styled(ElementWrapper)`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.6rem;

  & > img {
    margin-right: 0.25rem;
  }
`;

const StripeLink = styled.a`
  display: flex;
  margin-right: auto;
`;

const PaymentTeaser = styled.img`
  height: 24px;
  margin-left: 0.2rem;
`;

const PaymentTeasers = styled.span`
  display: flex;
`;

const Separator = styled.div`
  display: flex;
  margin: 0.75rem 0 0.5rem;
  align-items: center;

  &:after {
    content: "";
    border-top: 1px solid ${props => props.theme.gray200};
    flex: 1;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  @media (${props => props.theme.tabletScreen}) {
    margin: 1rem 0 0.7rem;
  }
`;

const Mandate = styled.p`
  font-size: small;
  text-align: justify;
  strong {
    color: ${props => props.theme.info};
  }
`;

const PaymentTypes = styled.ol`
  list-style: none;
  margin: 1rem auto;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
`;

const PaymentType = styled.li`
  flex: 1;
  min-width: 100px;
  max-width: 100px;
  font-size: ${props => props.theme.fontSizes.tiny};
  border-radius: ${props => props.theme.borderRadius};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 0.5rem;
  background-color: ${props => props.theme.light};
  color: ${props => (props.active ? props.theme.info : props.theme.dark)};
  text-align: center;
  cursor: pointer;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  transition: box-shadow 1s;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    box-shadow: ${props => props.theme.boxShadowSmall};
  }

  @media (${props => props.theme.tabletScreen}) {
    min-width: 128px;
    max-width: 160px;
    font-size: ${props => props.theme.fontSizes.default};

    padding: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`;

const ExternalPaymentHint = styled.p`
  margin: 1rem auto;
  color: ${props => props.theme.info};
  background-color: ${props => props.theme.light};
  border-radius: ${props => props.theme.borderRadius};
  padding: 1rem;
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > i {
    margin-left: 1rem;
    display: none;
  }

  @media (${props => props.theme.tabletScreen}) {
    margin: 2rem auto;
    max-width: 480px;

    & > i {
      display: block;
    }
  }
`;

const Label = styled.label`
  display: block;
  margin-top: 0.4rem;
  margin-bottom: 0.125rem;
`;

const TermsLink = styled.p`
  margin-bottom: 0.125rem;
  @media (${props => props.theme.tabletScreen}) {
    margin-bottom: 0.5rem;
  }
`;

const CTA = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.25rem 0 0.5rem;

  button {
    flex: 1;
  }

  ${TermsLink} {
    font-size: ${props => props.theme.fontSizes.tiny};
  }

  @media (${props => props.theme.tabletScreen}) {
    margin-top: 3rem;
    ${TermsLink} {
      display: block;
    }
  }

  // Android Chrome soft keyboard
  @media (max-height: 400px) {
    button {
      margin: 1rem 0 0.25rem;
    }
  }
`;

const CheckOutActions = styled.div`
  display: flex;
  flex-direction: column;
`;

const BorderedCol = styled(Col)`
  border-left: 1px solid ${props => props.theme.gray200};
`;

// TODO: move check out form to own page / view due to cancelation restirctions -> onProcessingStateChange
const Checkout = ({ booking, intl, onSuccess, onProcessingStateChange, onCancel }) => {
  const rollbar = useRollbar();
  const stripe = useStripe();
  const elements = useElements();
  const user = booking.user;

  const [isLoading, setIsLoading] = useState(false);
  const [accountHolderName, setAccountHolderName] = useState(
    [user.firstName, user.lastName].join(" "),
  );
  const [accountHolderEmail, setAccountHolderEmail] = useState(user.email);
  const [paymentType, setPaymentType] = useState(
    booking.paymentMethod &&
      validPaymentType(booking.paymentMethod.type, booking.validPaymentMethodTypes) ===
        booking.paymentMethod.type
      ? PAYMENT_TYPES.SAVED
      : validPaymentType(PAYMENT_TYPES.CARD, booking.validPaymentMethodTypes),
  );

  useEffect(() => {
    onProcessingStateChange(isLoading);
  }, [onProcessingStateChange, isLoading]);

  // Submit the chosen payment type to stripe
  const submitPayment = async e => {
    if (e) {
      e.preventDefault();
      // Native form validation
      if (e.target.checkValidity && !e.target.checkValidity()) {
        console.warn("Couldn't submit form, because form is not valid.");
        return;
      }
    }

    if (isLoading || !stripe || !elements) {
      console.warn(
        "Couldn't submit form, because it is already processing a payment or dependencies are missing:",
        isLoading,
        stripe,
        elements,
      );
      return;
    }

    setIsLoading(true);

    // If this call fails, the booking isn valid anymore (too old, some else took the last spot meanwhile, etc..)
    try {
      await new ApiClient().validateBooking(booking.id);
    } catch (e) {
      setIsLoading(false);

      if (e.isAxiosError) {
        // Capture Axios 0 - response code
        return onPaymentError((e.response || {}).data || { code: 0 });
      }

      rollbar.error("Error validating booking", e, { booking });
      throw e;
    }

    // Send in snake case, as no auto convert is applied
    const billing_details = {
      name: accountHolderName,
      email: accountHolderEmail,
      phone: user.phone,
      address: {
        city: user.address.city,
        line1: user.address.street,
        postal_code: user.address.postalCode,
        country: user.address.country,
      },
    };

    // The booking is still valid, so proceed with payment charge
    switch (paymentType) {
      // If booking was created with a customer and a valid payment method
      // we can just confirm the payment intent
      case PAYMENT_TYPES.SAVED: {
        if (booking.paymentMethod) {
          switch (booking.paymentMethod.type) {
            case PAYMENT_TYPES.CARD: {
              return await stripe
                .confirmCardPayment(booking.clientSecret, {
                  payment_method: booking.paymentMethod.id,
                })
                .then(result => {
                  setIsLoading(false);
                  result.paymentIntent
                    ? onSuccess(result.paymentIntent)
                    : onPaymentError(result.error);
                });
            }
            case PAYMENT_TYPES.SEPA: {
              return await stripe
                .confirmSepaDebitPayment(booking.clientSecret, {
                  payment_method: booking.paymentMethod.id,
                })
                .then(result => {
                  setIsLoading(false);
                  result.paymentIntent
                    ? onSuccess(result.paymentIntent)
                    : onPaymentError(result.error);
                });
            }
            default: {
              // If payment type is neither card nor sepa, try to confirm it from server
              return onSuccess({});
            }
          }
        } else {
          return onPaymentError({ code: "no_payment_method" });
        }
      }

      // Card payment has a slightly different approach and uses payment intents to
      // handle payments (incl. 3D or 2FA for new payment regulations)
      case PAYMENT_TYPES.CARD: {
        const cardElement = elements.getElement(CardElement);
        return await stripe
          .confirmCardPayment(booking.clientSecret, {
            payment_method: {
              card: cardElement,
              billing_details,
            },
          })
          .then(result => {
            setIsLoading(false);
            result.paymentIntent ? onSuccess(result.paymentIntent) : onPaymentError(result.error);
          });
      }

      // With all capabilites include sepa_debit_payment we can now use SEPA payment
      // with payment intents
      case PAYMENT_TYPES.SEPA: {
        const ibanElement = elements.getElement(IbanElement);

        return await stripe
          .confirmSepaDebitPayment(booking.clientSecret, {
            payment_method: {
              sepa_debit: ibanElement,
              billing_details,
            },
          })
          .then(result => {
            setIsLoading(false);
            result.paymentIntent ? onSuccess(result.paymentIntent) : onPaymentError(result.error);
          });
      }

      // Giropay is handled outside of page scope, we just pass the account holder name
      // and wait for the redirect
      case PAYMENT_TYPES.GIRO_PAY: {
        const { error } = await stripe.confirmGiropayPayment(booking.clientSecret, {
          payment_method: {
            billing_details,
          },
          return_url: checkoutRedirectUrl(booking),
        });

        if (error) {
          onPaymentError(error);
        }

        return;
      }

      // SOFORT is handled outside of page scope, we just pass the account holder name
      // and wait for the redirect
      case PAYMENT_TYPES.SOFORT: {
        const { error } = await stripe.confirmSofortPayment(booking.clientSecret, {
          payment_method: {
            sofort: {
              country: billing_details.address.country,
            },
          },
          return_url: checkoutRedirectUrl(booking),
        });

        if (error) {
          onPaymentError(error);
        }

        return;
      }

      default: {
        return setIsLoading(false);
      }
    }
  };

  // TODO: If any error happen which couldn't be handled in the Stripe
  // checkout, reject the booking.
  const onPaymentError = error => {
    rollbar.error("Error submitting payment", error, { booking });

    const errorMessage = intl.formatMessage({ id: `Stripe.errors.${error.code}` });
    window.alert(
      errorMessage.includes("Stripe.errors")
        ? intl.formatMessage({ id: "components.Booking.error.payment" })
        : errorMessage,
    );
  };

  // Handle direct payment like ApplePay or GooglePay
  // TODO: This could be better placed in the booking as it should ease the
  //       checkout to not type in all the infos (needs work in Backend too)
  const onDirectPayment = async () => {
    return await stripe.confirmCardPayment(booking.clientSecret).then(result => {
      setIsLoading(false);
      result.paymentIntent ? onSuccess(result.paymentIntent) : onPaymentError(result.error);
    });
  };

  const contentByType = () => {
    if (paymentType === PAYMENT_TYPES.SAVED && booking.paymentMethod) {
      return (
        <Col>
          <Label>
            <FormattedMessage id="components.Booking.accountHolderName" />
          </Label>
          <Input name="accountHolderName" value={accountHolderName} required readOnly />
          {booking.paymentMethod.type === "sepa_debit" && (
            <>
              <Label>
                <FormattedMessage id="components.Booking.accountHolderEmail" />
              </Label>
              <Input name="accountHolderEmail" value={accountHolderEmail} required readOnly />
              <Label>
                <FormattedMessage id="components.Booking.checkout.iban" />
              </Label>
              <ElementWrapperSaved>
                <PaymentTeaser src={sepa} alt="Sepa Lastschrift" />
                {`DE xxxx xxxx xxxx ${booking.paymentMethod.last4}`}
              </ElementWrapperSaved>
            </>
          )}
          {booking.paymentMethod.type === "card" && (
            <ElementWrapperSaved>
              <PaymentTeaser src={visa} alt="Visa" />
              {`xxxx xxxx xxxx ${booking.paymentMethod.last4}`}
            </ElementWrapperSaved>
          )}
        </Col>
      );
    }
    if (paymentType === PAYMENT_TYPES.CARD) {
      return (
        <Col>
          <Label>
            <FormattedMessage id="components.Booking.accountHolderName" />
          </Label>
          <Input
            name="accountHolderName"
            value={accountHolderName}
            placeholder={intl.formatMessage({ id: "components.Booking.accountHolderName" })}
            onChange={({ target: { value } }) => setAccountHolderName(value)}
            hasError={accountHolderName === ""}
            required
          />
          <Label>
            <FormattedMessage id="components.Booking.checkout.cardNo" />
          </Label>
          <ElementWrapper>
            <CardElement options={{ style: stripeElementStyle }} />
          </ElementWrapper>
        </Col>
      );
    }
    if (paymentType === PAYMENT_TYPES.SOFORT) {
      return (
        <Col>
          <ExternalPaymentHint>
            <FormattedMessage id="components.Booking.sofortHint" />
            <Icon name="chevron-up" color="info" direction="right" size="small" />
          </ExternalPaymentHint>
        </Col>
      );
    }
    if (paymentType === PAYMENT_TYPES.SEPA) {
      return (
        <Col>
          <Label>
            <FormattedMessage id="components.Booking.accountHolderName" />
          </Label>
          <Input
            name="accountHolderName"
            value={accountHolderName}
            placeholder={intl.formatMessage({ id: "components.Booking.accountHolderName" })}
            onChange={({ target: { value } }) => setAccountHolderName(value)}
            hasError={accountHolderName === ""}
            required
          />
          <Label>
            <FormattedMessage id="components.Booking.accountHolderEmail" />
          </Label>
          <Input
            name="accountHolderEmail"
            value={accountHolderEmail}
            placeholder={intl.formatMessage({ id: "components.Booking.accountHolderEmail" })}
            onChange={({ target: { value } }) => setAccountHolderEmail(value)}
            hasError={accountHolderEmail === ""}
            type="email"
            required
          />
          <Label>
            <FormattedMessage id="components.Booking.checkout.iban" />
          </Label>
          <ElementWrapper>
            <IbanElement
              options={{
                supportedCountries: ["SEPA"],
                placeholderCountry: "DE",
                style: stripeElementStyle,
              }}
            />
          </ElementWrapper>
          <Mandate>
            <FormattedMessage id="components.Booking.checkout.sepaMandate" />
            <br />
            <FormattedHTMLMessage id="components.Booking.checkout.sepaDisputeInfo" />
          </Mandate>
        </Col>
      );
    }
    if (paymentType === PAYMENT_TYPES.GIRO_PAY) {
      return (
        <Col>
          <Label>
            <FormattedMessage id="components.Booking.accountHolderName" />
          </Label>
          <Input
            name="accountHolderName"
            value={accountHolderName}
            placeholder={intl.formatMessage({ id: "components.Booking.accountHolderName" })}
            onChange={({ target: { value } }) => setAccountHolderName(value)}
            hasError={accountHolderName === ""}
            required
          />
          <ExternalPaymentHint>
            <FormattedMessage id="components.Booking.giropayHint" />
            <Icon name="chevron-up" color="info" direction="right" size="small" />
          </ExternalPaymentHint>
        </Col>
      );
    }
    return "No Payment Type";
  };

  return (
    <Row>
      {isLoading && (
        <LoadingContainer>
          <Loading text={<FormattedMessage id="components.Booking.loading.payment" />} />
        </LoadingContainer>
      )}

      <Col count={7}>
        <Wrapper>
          <Title>
            <Heading5>
              <FormattedMessage id="components.Booking.checkout.title" />
            </Heading5>
            <Hidden size="sm">
              <PaymentTeasers>
                <PaymentTeaser src={visa} alt="Visa" />
                <PaymentTeaser src={masterCard} alt="MasterCard" />
                <PaymentTeaser src={sepa} alt="SEPA Lastschrift" />
                <PaymentTeaser src={sofort} alt="SOFORT" />
                <PaymentTeaser src={giropay} alt="giropay" />
              </PaymentTeasers>
            </Hidden>
          </Title>

          <Separator>
            <FormattedMessage id="components.Booking.checkout.choosePaymentMethod" />
          </Separator>

          <PaymentTypes>
            {booking.paymentMethod && (
              <PaymentType
                active={paymentType === PAYMENT_TYPES.SAVED}
                onClick={() => setPaymentType(PAYMENT_TYPES.SAVED)}
              >
                <Icon
                  name="money-check"
                  color={paymentType === PAYMENT_TYPES.SAVED ? "info" : "black"}
                  size="large"
                />
                <FormattedMessage id="components.Booking.checkout.saved" />
              </PaymentType>
            )}

            {(booking.validPaymentMethodTypes || values(PAYMENT_TYPES)).map(
              availablePaymentType => (
                <PaymentType
                  key={availablePaymentType}
                  active={paymentType === availablePaymentType}
                  onClick={() => setPaymentType(availablePaymentType)}
                >
                  {(availablePaymentType === PAYMENT_TYPES.CARD ||
                    availablePaymentType === PAYMENT_TYPES.SEPA) && (
                    <Icon
                      name={paymentTypeIcon(availablePaymentType)}
                      color={paymentType === availablePaymentType ? "info" : "black"}
                      size="large"
                    />
                  )}
                  {availablePaymentType === PAYMENT_TYPES.SOFORT && (
                    <PaymentTeaser src={sofort} alt="SOFORT" />
                  )}
                  {availablePaymentType === PAYMENT_TYPES.GIRO_PAY && (
                    <PaymentTeaser src={giropay} alt="giropay" />
                  )}
                  <FormattedMessage id={`components.Booking.checkout.${availablePaymentType}`} />
                </PaymentType>
              ),
            )}
          </PaymentTypes>

          <Row>{contentByType()}</Row>

          <ActionBar>
            <StripeLink href="https://stripe.com" target="_blank">
              <img src={stripeLogo} alt="Powered by Stripe" />
            </StripeLink>
          </ActionBar>
          <Hidden size="sm" style={{ marginTop: "auto" }}>
            <CancelLink href="#" onClick={onCancel}>
              <FormattedMessage id="actions.cancelBooking" />
            </CancelLink>
          </Hidden>
        </Wrapper>
      </Col>
      <BorderedCol count={5}>
        <SummaryWrapper>
          <Summary
            participants={booking.participants}
            costs={booking.costs}
            serviceFee={booking.serviceFee}
            voucher={booking.voucher}
            priceOption={booking.priceOption}
            course={booking.course}
            event={booking.event}
            provider={booking.provider}
          />
          <CTA>
            <TermsLink as="div">
              <TermsLink>
                {booking.provider.hasTerms ? (
                  <FormattedHTMLMessage
                    id="components.Booking.termsProvider"
                    values={{
                      name: booking.provider.name,
                      slug: booking.provider.slug,
                    }}
                  />
                ) : (
                  <FormattedHTMLMessage id="components.Booking.terms" />
                )}
              </TermsLink>
              <TermsLink>
                {booking.provider.hasPrivacyPolicy ? (
                  <FormattedHTMLMessage
                    id="components.Booking.privacyPolicyProvider"
                    values={{
                      name: booking.provider.name,
                      slug: booking.provider.slug,
                    }}
                  />
                ) : (
                  <FormattedHTMLMessage id="components.Booking.privacyPolicy" />
                )}
              </TermsLink>
            </TermsLink>

            <CheckOutActions>
              <DirectPay booking={booking} onPay={onDirectPayment} />
              <ActionBar>
                <Hidden size="md, lg">
                  <Button
                    type="button"
                    color="default"
                    glow={false}
                    onClick={e => {
                      e.preventDefault();
                      onCancel();
                    }}
                    style={{ flex: 0, marginRight: "0.5rem" }}
                  >
                    <Icon name="xmark" />
                  </Button>
                </Hidden>
                <Button
                  type="submit"
                  color="success"
                  glow
                  busy={isLoading}
                  onClick={submitPayment}
                  disabled={false}
                >
                  <FormattedMessage id="actions.pay" />
                </Button>
              </ActionBar>
            </CheckOutActions>
          </CTA>
        </SummaryWrapper>
      </BorderedCol>
    </Row>
  );
};

Checkout.propTypes = {
  booking: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onProcessingStateChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default injectIntl(Checkout);
