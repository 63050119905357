import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { sortBy } from "lodash";

import { FormattedMessage, FormattedHTMLMessage, FormattedNumber, injectIntl } from "react-intl";
import { formatAddress, formatAgeSpan, currencyFormat } from "../../utils/formatters";
import { flexibleEvents, onDemandEvents } from "../../utils/courses";

import Event from "./Event";
import Franchise from "../../components/Franchise";
import ContactForm from "../../components/ContactForm";
import { Heading4, InfoList, Contact, Small, Icon, Button } from "../../components";
import Loading, { LoadingContainer } from "../../components/Loading";
import { differentTimeZone } from "../../utils/common";
import { rgba } from "polished";

const Wrapper = styled.aside``;

const InnerWrapper = styled.div`
  background-color: ${props => props.theme.gray100};
  padding: 0 1rem 0.5rem 1rem;
  border-radius: ${props => props.theme.borderRadius};
  margin-bottom: 1.5rem;

  @media (${props => props.theme.tabletScreen}) {
    padding: 0 1rem 1rem 1rem;
  }
`;

const SidebarElement = styled.section`
  margin-bottom: 2.5rem;
`;

const AdditionalCostInfo = styled(Small)`
  display: block;
  font-family: ${props => props.theme.fontFamilyBase};
  color: ${props => props.theme.gray600};
`;

const EventsWrapper = styled.section`
  display: block;
  height: auto;
  opacity: 1;
  padding: 0;
`;

const EventsWrapperPopUp = styled(EventsWrapper)`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: ${props => (props.visible ? "85%" : "0")};
  background-color: ${props => props.theme.white};
  border-radius: ${props => props.theme.borderRadius};
  padding: 1rem;
  z-index: 7;
  transform-style: preserve-3d;
  opacity: ${props => (props.visible ? "1" : "0")};

  transition: opacity 250ms ease-out, height 250ms ease-out;

  &:after {
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 100%;
    left: 0;
    margin-bottom: -${props => props.theme.borderRadius};
    background-color: rgba(0, 0, 0, 0.33);
    content: "";
    z-index: -1;
    transform: translateZ(-1px);
  }

  @media (${props => props.theme.tabletScreen}) {
    display: none;
  }
`;

const Events = styled.div`
  position: relative;
  min-height: 100%;
  max-height: 100%;
  z-index: 6;
  overflow: auto;
  padding-bottom: 7rem;
`;

const EventsToggleButton = styled(Button)`
  position: fixed;
  min-width: 94%;
  min-height: 3.5rem;
  left: 50%;
  transform: translateX(-50%);
  bottom: ${props => (props.eventsVisible ? "1rem" : "5rem")};
  z-index: ${props => (props.eventsVisible ? "9" : "7")};
  color: ${props => props.theme.white};
  box-shadow: ${props => props.theme.boxShadow};
  border-radius: ${props => props.theme.borderRadiusHuge};
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${props => props.theme.fontSizes.default};

  transition: bottom 250ms ease-out;

  & > i {
    margin-right: 1rem;
  }

  @media (${props => props.theme.tabletScreen}) {
    display: none;
  }

  bottom: @media (${props => props.theme.tabletScreen}) {
    display: none;
  }
`;

const TimeZoneWarning = styled.p`
  display: inline-block;
  padding: 0.5rem 0.5rem 1rem;
  margin: 1rem;
  border-radius: ${props => props.theme.borderRadius};
  background-color: ${props => rgba(props.theme.warning, 0.1)};
  color: ${props => props.theme.dark};
  text-align: center;
`;

const Details = ({ contacts, course, intl }) => {
  const [showEvents, setShowEvents] = useState(false);

  const flexibleEvent = flexibleEvents(course.events)[0];
  const onDemandEvent = onDemandEvents(course.events)[0];

  const dateEvents = (course.events || []).filter(e => e.startDate);
  const flexEvents = (course.events || []).filter(e => !e.startDate);

  const multiplePrices = course.priceTo && course.priceFrom !== course.priceTo;
  const showServiceFee =
    !course.serviceFeeIncluded && course.serviceFeeFrom > 0 && course.hasPrices;

  return (
    <Wrapper>
      <InnerWrapper>
        <SidebarElement>
          {course.franchise && <Franchise franchise={course.franchise} />}
          <InfoList
            items={[
              {
                icon: "tag",
                label: <FormattedMessage id="course.attributes.costs" />,
                value:
                  course.priceFrom > 0 ? (
                    <>
                      {multiplePrices && <FormattedMessage id="priceFrom" />}
                      <FormattedNumber value={course.priceFrom / 100} {...currencyFormat(course)} />
                      {showServiceFee > 0 && (
                        <AdditionalCostInfo>
                          {course.priceFrom !== course.priceTo ? (
                            <FormattedMessage id="course.attributes.additionalFeeGeneric" />
                          ) : (
                            <span>
                              <FormattedMessage id="course.attributes.additionalFee" />
                              <FormattedNumber
                                value={course.serviceFeeFrom}
                                {...currencyFormat(course)}
                              />
                            </span>
                          )}
                        </AdditionalCostInfo>
                      )}
                    </>
                  ) : course.priceFrom !== course.priceTo ? (
                    <span>
                      <FormattedMessage id="priceFrom" />
                      <FormattedNumber value={0} {...currencyFormat(course)} />
                    </span>
                  ) : (
                    <span>
                      <FormattedMessage id="components.Course.free" />
                    </span>
                  ),
              },
              !flexibleEvent &&
                !onDemandEvent && {
                  icon: "users",
                  label: <FormattedMessage id="course.attributes.participantCount" />,
                  value:
                    course.participantCount < 2 ? (
                      <FormattedMessage
                        id={`course.attributes.${
                          course.participantCount ? "one" : "zero"
                        }Participant`}
                      />
                    ) : (
                      course.participantCount
                    ),
                },
              {
                icon: "baby",
                label: <FormattedMessage id="course.attributes.age" />,
                value:
                  Math.max(course.ageMin, course.ageMax) > 0 ? (
                    formatAgeSpan(course.ageMin, course.ageMax)
                  ) : (
                    <FormattedMessage id="course.attributes.noAge" />
                  ),
              },
              {
                icon: "location-dot",
                label: "",
                value: course.flexibleLocation ? (
                  <FormattedHTMLMessage
                    id={`components.Course.flexibleLocation.${course.locationType}`}
                  />
                ) : course.address && !course.manyLocations ? (
                  formatAddress(course.address)
                ) : (
                  <FormattedMessage id="components.Course.manyLocations" />
                ),
              },
              {
                icon: "envelope",
                label: <FormattedMessage id="provider.attributes.contact" />,
                value: (
                  <ContactForm
                    provider={course.provider}
                    subject={intl.formatMessage(
                      { id: "pages.Course.messageSubject" },
                      { course: course.name, id: course.id },
                    )}
                  />
                ),
              },
            ].filter(Boolean)}
          />
        </SidebarElement>

        {contacts && contacts.length > 0 && (
          <SidebarElement>
            {(contacts || []).map(contact => (
              <Contact key={contact.id} user={contact} provider={course.provider} course={course} />
            ))}
          </SidebarElement>
        )}
      </InnerWrapper>
      <SidebarElement id="course-events">
        {!course.index ? (
          <EventsWrapper>
            <Heading4>
              <FormattedMessage id="appointments.many" />
            </Heading4>
            {differentTimeZone() && (
              <TimeZoneWarning>
                <Icon name="triangle-exclamation" spaced color="warning" />
                <FormattedMessage id="timezone.warning" />
              </TimeZoneWarning>
            )}
            <Events>
              {course.events.length > 0 ? (
                [
                  dateEvents
                    .sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate))
                    .map(courseEvent => (
                      <Event key={courseEvent.id} event={courseEvent} course={course} />
                    )),
                  sortBy(flexEvents, [e => (e.name || "").toLowerCase()]).map(courseEvent => (
                    <Event key={courseEvent.id} event={courseEvent} course={course} />
                  )),
                ]
              ) : (
                <Small>
                  <FormattedMessage id="components.Course.noEvents" />
                </Small>
              )}
            </Events>
          </EventsWrapper>
        ) : (
          <LoadingContainer>
            <Loading
              small
              shadow={false}
              text={<FormattedMessage id="components.Course.loadingEvents" />}
            />
          </LoadingContainer>
        )}

        {course.events.length > 0 && (
          <aside>
            <EventsToggleButton
              color="info"
              eventsVisible={showEvents}
              onClick={() => setShowEvents(!showEvents)}
            >
              {showEvents ? (
                <>
                  <Icon name="long-arrow-left" />
                  <FormattedMessage id="actions.back" />
                </>
              ) : (
                <FormattedMessage id="components.Course.showEvents" />
              )}
            </EventsToggleButton>
            <EventsWrapperPopUp visible={showEvents}>
              <Heading4>
                <FormattedMessage id="appointments.many" />
              </Heading4>
              {differentTimeZone() && (
                <TimeZoneWarning>
                  <Icon name="triangle-exclamation" spaced color="warning" />
                  <FormattedMessage id="timezone.warning" />
                </TimeZoneWarning>
              )}
              <Events>
                {course.events.length > 0 ? (
                  [
                    dateEvents
                      .sort((a, b) => Date.parse(a.startDate) - Date.parse(b.startDate))
                      .map(courseEvent => (
                        <Event key={courseEvent.id} event={courseEvent} course={course} />
                      )),
                    sortBy(flexEvents, [e => (e.name || "").toLowerCase()]).map(courseEvent => (
                      <Event key={courseEvent.id} event={courseEvent} course={course} />
                    )),
                  ]
                ) : (
                  <Small>
                    <FormattedMessage id="components.Course.noEvents" />
                  </Small>
                )}
              </Events>
            </EventsWrapperPopUp>
          </aside>
        )}
      </SidebarElement>
    </Wrapper>
  );
};

Details.propTypes = {
  course: PropTypes.object.isRequired,
  contacts: PropTypes.array,
};

Details.defaultProps = {
  contacts: [],
};

export default injectIntl(Details);
